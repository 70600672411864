import { MEETING_LINK, testimonials } from "./constants";
import { shuffleArrayObject } from "./module";

document.addEventListener("DOMContentLoaded", function () {
    const scrollTopButton = document.getElementById("scroll-top");
    const heroSection = document.querySelector("#hero"); // Adjust the selector to match your hero section

    if(scrollTopButton){
        // Show or hide the scroll-top button based on the scroll position
        window.addEventListener("scroll", function () {
            if (window.scrollY > heroSection.offsetHeight) {
                scrollTopButton.style.display = "block";
            } else {
                scrollTopButton.style.display = "none";
            }
        });
    
        // Scroll to the top when the button is clicked
        scrollTopButton.addEventListener("click", function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            scrollTopButton.style.display = "none"; // Hide the button after scrolling to top
        });
    }
});


// Shuffle the testimonials array
const shuffledTestimonials = shuffleArrayObject(testimonials);

// Function to create the rating stars based on the rating value
function createRatingStars(rating) {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.1;
  let starsHtml = '';

  for (let i = 0; i < fullStars; i++) {
    starsHtml += "<li><i class='bx bxs-star'></i></li>";
  }
  if (halfStar) {
    starsHtml += "<li><i class='bx bxs-star-half'></i></li>";
  }
  while (starsHtml.match(/bxs-star/g)?.length + starsHtml.match(/bxs-star-half/g)?.length < 5) {
    starsHtml += "<li><i class='bx bx-star'></i></li>";
  }

  return starsHtml;
}


// Function to create the testimonial slider items dynamically
function createTestimonialSliderItems(testimonials) {
  const sliderContainer = document.querySelector('.zubuz-testimonial-slider');

  if(!sliderContainer){
    console.log("Testimonials did not exists.");
    return;
  }

  // Create a context for all images in the directory
  const images = require.context('../../assets/images/testimonials_icon', false, /\.(png|jpe?g|gif|svg|webp)$/);

  testimonials.forEach(testimonial => {
    // Create the slider item
    const sliderItem = document.createElement('div');
    sliderItem.classList.add('zubuz-testimonial-slider-item');

    // Create the slider data
    const sliderData = document.createElement('div');
    sliderData.classList.add('zubuz-testimonial-slider-data');

    // Create the rating stars
    const ratingStars = document.createElement('div');
    ratingStars.classList.add('zubuz-testimonial-slider-rating');
    ratingStars.innerHTML = `<ul>${createRatingStars(testimonial.rating)}</ul>`;

    // Create the feedback and heading
    const feedbackHeading = document.createElement('h3');
    feedbackHeading.textContent = testimonial.title;

    const feedbackText = document.createElement('p');
    feedbackText.textContent = testimonial.feedback;

    // Append rating and feedback to slider data
    sliderData.appendChild(ratingStars);
    sliderData.appendChild(feedbackHeading);
    sliderData.appendChild(feedbackText);

    // Create the slider author section
    const sliderAuthor = document.createElement('div');
    sliderAuthor.classList.add('zubuz-testimonial-slider-author');

    const logo = images(testimonial.picture);
    console.log('logo:', logo);

    // Author thumb image
    const authorThumb = document.createElement('div');
    authorThumb.classList.add('zubuz-testimonial-slider-author-thumb');
    authorThumb.innerHTML = `<img src="${logo}" alt="${testimonial.name}">`;

    // Author data
    const authorData = document.createElement('div');
    authorData.classList.add('zubuz-testimonial-slider-author-data');

    const authorName = document.createElement('span');
    authorName.textContent = testimonial.name;

    const authorTitle = document.createElement('p');
    authorTitle.textContent = `- ${testimonial.location}`;

    // Append thumb and data to author
    authorData.appendChild(authorName);
    authorData.appendChild(authorTitle);

    sliderAuthor.appendChild(authorThumb);
    sliderAuthor.appendChild(authorData);

    // Append slider data and author to the slider item
    sliderItem.appendChild(sliderData);
    sliderItem.appendChild(sliderAuthor);

    // Append the slider item to the slider container
    sliderContainer.appendChild(sliderItem);
  });
}

// Execute the function to create the items
createTestimonialSliderItems(shuffledTestimonials);


const meetingContainer = document.querySelector(".meeting-container");
if (meetingContainer) {
  // Insert the HTML content without the script tag
  meetingContainer.innerHTML = `
    <div class="meetings-iframe-container" data-src="${MEETING_LINK}?embed=true"></div>
  `;

  // Dynamically create the script element
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
  script.async = true; // Optional: loads the script asynchronously

  // Append the script to the meetingContainer or to the document head/body
  meetingContainer.appendChild(script);
}

function setupDownloadBrochure() {
  // Create a context for all files in the directory
  const brochuresContext = require.context('../../assets/brochures', false, /\.pdf$/);

  // Define the brochures array with IDs and file names
  const brochureArray = [
    {
      id: "download-ppc-va",
      fileName: "ppc-brochure-&-pricing.pdf"
    },
    {
      id: "download-hubspot-va",
      fileName: "InsightsTap - HubSpot VA Brochure & Pricing.pdf"
    },
    {
      id: "download-analytics-studio",
      fileName: "InsightsTap - Analytics Studio Brochure & Pricing.pdf"
    },
    {
      id: "download-hubspot-development",
      fileName: "InsightsTap - HubSpot Development Brochure & Pricing.pdf"
    }
  ];

  brochureArray.forEach(brochure => {
    const downloadBrochure = document.querySelector(`#${brochure.id}`);
    if (downloadBrochure) {
      downloadBrochure.addEventListener("click", function() {
        try {
          // Use require.context to get the file URL
          const fileURL = brochuresContext(`./${brochure.fileName}`);
          console.log("fileURL:", JSON.stringify(fileURL)); // Check if URL is correct
          
          // Create an anchor element and set attributes for download
          const anchor = document.createElement('a');
          anchor.href = fileURL;
          anchor.download = brochure.fileName;
          anchor.style.display = 'none';
          
          // Append anchor to the body, trigger click, and remove it
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } catch (error) {
          console.error("Error loading file:", error);
        }
      });
    }
  });
}

setupDownloadBrochure();

async function fetchAndDownload(url, fileName) {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

// Usage
