import { CONSOLE_LOG, footerData, menuData } from "./constants";
import { getCurrentPageIndex, modifyPageURL, HAS_EXTERNAL_PATH } from "./module";
import logo from "../images/logo/logo-dark.png";
console.log("LOGO:", logo);
document.addEventListener("DOMContentLoaded", function () {
    renderFooter();
});

document.addEventListener('DOMContentLoaded', () => {
    const menu = createMenu(menuData);
});

function createMenu(menuData) {
    const ul = document.querySelector(".site-menu-main");
  
    menuData.forEach(item => {
        const li = document.createElement('li');
        li.className = 'nav-item';
    
        const a = document.createElement('a');

        const itemURL = modifyPageURL(item.link);
        a.href = itemURL;

        a.className = 'nav-link-item';
        a.textContent = item.label;
  
        li.appendChild(a);
  
      if (item.children) {
        li.classList.add('nav-item-has-children');
        a.innerHTML += `<i class="fas fa-angle-down"></i>`;
        const subUl = document.createElement('ul');
        subUl.className = 'sub-menu';
  
        item.children.forEach(subItem => {
          const subLi = document.createElement('li');
          subLi.className = 'sub-menu--item';
  
          const subA = document.createElement('a');
          subA.href = subItem.link;
          subA.innerHTML = `<span class="menu-item-text">${subItem.label}</span>`;
  
          subLi.appendChild(subA);
          subUl.appendChild(subLi);
        });
  
        li.appendChild(subUl);
      }
  
      ul.appendChild(li);
    });
  
    return true;
}

function renderFooter() {
    const footerContainer = document.querySelector('.zubuz-footer-top .row');
    if (!footerContainer) {
        console.error("Footer container not found!");
        return;
    }

    const col1 = document.createElement('div');
    col1.classList.add('col-xl-4', 'col-lg-12');
    col1.innerHTML = `            
        <div class="zubuz-footer-textarea">
            <a href="">
                <img src="${logo}" alt="">
            </a> 
            <div class="">
                <div class="zubuz-footer-title">
                    <p class="mb-2">Registered Address</p>
                </div>
                <p>${footerData.address}</p>
            </div>
        </div>`;
        // <p class="mb-2">${footerData.description}</p>

    const col2 = document.createElement('div');
    col2.classList.add('col-xl-8', 'col-lg-12', 'row');

    footerData.subMenu.forEach((section, index) => {
        const col = document.createElement('div');
        col.classList.add('col-xl-4', 'col-md-4');

        const linksHtml = section.links.map(link => {
            var itemURL = modifyPageURL(link.url);

            // fullPath = !HAS_EXTERNAL_PATH && link.path !== "0" ? link.path + link.url : link.url;
            return `<li><a href="${itemURL}">${link.text}</a></li>`;
        }).join('');
        
        
        col.innerHTML = `
            <div class="zubuz-footer-menu">
                <div class="zubuz-footer-title">
                    <p>${section.title}</p>
                </div>
                <ul>${linksHtml}</ul>
            </div>
        `;
        col2.appendChild(col);
    });

    footerContainer.appendChild(col1);
    footerContainer.appendChild(col2);

    const socialIconsHtml = footerData.socialMedia.map(icon => `
        <li><a href="${icon.href}" target="_blank"><i class="${icon.iconClass}"></i></a></li>
    `).join('');

    const footerBottom = document.querySelector('.zubuz-footer-bottom');
    if (footerBottom) {
        footerBottom.innerHTML = `
            <div class="zubuz-social-icon order-md-2"><ul>${socialIconsHtml}</ul></div>
            <div class="zubuz-copywright"><p>${footerData.footerBottom}</p></div>`;
    } else {
        console.error("Footer bottom section not found!");
    }
}
