export const HAS_EXTERNAL_PATH = hasExternalPathFunction();
console.log("HAS_EXTERNAL_PATH:", HAS_EXTERNAL_PATH);

function hasExternalPathFunction() {
    const path = window.location.pathname;
    return path.split('/').length > 2;
}

// Function to shuffle the testimonials array
export function shuffleArrayObject(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getCurrentPageIndex() {
  // Get the current URL
  const currentUrl = window.location.href;

  // Parse the URL to get the path
  const url = new URL(currentUrl);
  const path = url.pathname;

  // Split the path into segments
  const segments = path.split('/').filter(segment => segment.length > 0);

  // Determine the current index
  const currentIndex = segments.length;

  // Output the result
  // console.log(`CurrentURL: ${currentUrl}`);
  //console.log(`CurrentIndex: ${currentIndex}`);
  
  return currentIndex;
}

export function modifyPageURL(pageUrl) {
  // Determine the current index
  const currentIndex = getCurrentPageIndex();

  // Check if the URL should be skipped
  if (!shouldSkipUrl(pageUrl)) { // Fixed the 'url' to 'pageUrl' here
    if (currentIndex === 2) {
      pageUrl = "../" + pageUrl;
    }
  }

  // Output the result
  //console.log(`PageURL: ${pageUrl}`);
  return pageUrl;
}

// The `shouldSkipUrl` function definition should be outside or imported
export function shouldSkipUrl(url) {
  const skipPrefixes = ['https://', 'mailto:', 'tel:'];
  return skipPrefixes.some(prefix => url.startsWith(prefix));
}
